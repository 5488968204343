// screens
const screenXxs = 330
const screenXs = 480
const screenS = 736
const screenM = 980
const screenL = 1280
const screenXl = 1690
const screenXxl = 1930

export const themeObject = {
    screens: {
        screenXxs: `${screenXxs}px`,
        screenXs: `${screenXs}px`,
        screenS: `${screenS}px`,
        screenM: `${screenM}px`,
        screenL: `${screenL}px`,
        screenXl: `${screenXl}px`,
        screenXxl: `${screenXxl}px`,
    }
}

export type Theme = Readonly<typeof themeObject>
export type StyledWithTheme = { theme: Theme }

export default themeObject
