import { createGlobalStyle } from 'styled-components'

export const GlobalStyles = createGlobalStyle`
    * {
        border: 0;
        margin: 0;
        padding: 0;
        outline: 0;
    }

    ::selection{
        background: #000000;
        color: #ffffff;
    }
    ::-moz-selection{
        background: #000000;
        color: #ffffff;
    }

    html {
        -webkit-font-smoothing: antialiased;
        -moz-osx-font-smoothing: grayscale;
        -ms-text-size-adjust: 100%;
        -webkit-text-size-adjust: 100%;
    }

    body {
        font-family: 'Option';
        font-weight: normal;
        font-style: normal;
        font-size: 16px;
        -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
        -webkit-font-smoothing: antialiased;
    }
`;
